import React from 'react';
import Header from '../components/Header';

import Footer from '../components/Footer';
import Layout from '../components/Layout';
import { Link } from 'gatsby';

const IndexPage = () => (
  <Layout>
    <section>
        <div className="container">
          <Header />
          <h2>
            Privacy
          </h2>

            <br />
            <p>
              David Hunt ("we", "our", "us" or "Nearo") is committed to protecting
              the privacy of all users of our mobile application ("services").
              Please read the following privacy policy that explains how we use and protect your information.
              We’ll be the "data controllers" of the information you provide to us.
            </p>

            <h4>Contact Details</h4>
            <p>
              If you have any queries or requests concerning this privacy policy or how we handle your data more generally,
              please get in touch with us at <a href="mailto:info@nearo.co">info@nearo.co</a>.
            </p>

            <h4>Information we collect</h4>
            <p>
              We will sometimes collect information from you when you use the nearo mobile application. We collect information:
              <uL>
                <li>When you create an account, we record you first name and email from the authentication provider that you choose (Facebook, Google, Apple)</li>
                <li>When you contact us directly via email or one of our social channels</li>
                <li>An approximate location 
                  <uL>
                    <li>for when you create a post</li>
                    <li>in order to provide you with the nearest posts</li>
                    <li>to notify you of someone who posted nearby</li>
                  </uL>
                All this information is associated with a random id.</li>
              </uL>
            </p>

            <h4>Use of information</h4>
            <p>
              The Data concerning the User is collected to allow the Owner to provide its Service,
              comply with its legal obligations, respond to enforcement requests,
              protect its rights and interests (or those of its Users or third parties), detect any malicious or fraudulent activity, as well as
              the following: Contacting the User, Managing contacts and sending messages and Beta Testing.
            </p>

            <h4>Sharing of information</h4>
            <p>
              <p>
                <b>We do not sell your data.</b>
                </p>

              <p>
                Within the app, when you make a post, the contents of that post (text and images) is displayed to all registered users within a 50km radius.
                Along with the post contents we also take record and display your name, the date and time the post was taken and an approximate location of
                where the post was made.
              </p>

              <p>
                When you leave a comment on a post we display your name and the contents of your comment along with the date and time the comment was posted.
              </p>

              <p>
                So, if you don’t want to share something publicly, don’t post or comment it.
              </p>

              <p>
                We store your user data, your post information and comment information (all as described above) with a third party cloud service provider. When we do so,
                we require those third parties to protect your personal information in accordance with this Policy and applicable laws. The data is stored in the United States.
                Please be advised that you are transferring your personal information to us in the United States no matter where you are located, and by using our Services,
                you consent to that transfer and use of your personal information in accordance with this Policy.
              </p>
            </p>

            <h4>Delete Personal Information</h4>
            <p>
              You can request to delete your information stored by Nearo by contacting us at <a href="mailto:info@nearo.co">info@nearo.co</a>.
              Simply email us, providing the email you have registered with and we will remove any user information (email and name) and posts related
              to that account. Please note that once this information is deleted that you will have to create a new account to access the Nearo application
              in the future.
            </p>

            <h4>Children under the age of 13</h4>
            <p>
              Our services are not intended for the use of children under the age of 13. We don not knowingly
              collect personal information from children under 13.
            </p>

            <h4>Communication</h4>
            <p>
              We may send you information about the platform and the service, you may unsubscribe if you wish to from any marketing material.
              When you contact us via emial we will store this contact information in order to reply in future correspondances. 
            </p>

            <h4>Your rights</h4>
            <p>
              Under data protection law, you may have a number of rights concerning the data we hold about you. If you wish to 
              exercise any of these rights, please contact us at <a href="mailto:info@nearo.co">info@nearo.co</a>. 
              For additional information on your rights please contact your data protection authority.

              If you cannot update your own information, we will correct any errors in the data we hold about you within 7 days 
              of receiving written notice about those errors.

              It is your responsibility to provide us with accurate and truthful data. We cannot be liable for any information 
              that is provided to us that is incorrect.
            </p>

            <h4>Changes to our Privacy Policy</h4>
            <p>
              Any changes to our privacy policy will be posted to the Sites and, where appropriate, we will notify you of the changes 
              for example by email or push notification. We may do things in addition to what is stated in this Privacy Policy to comply 
              with the GDPR and nothing in this Privacy Policy shall deem us to have not complied with the GDPR.
            </p>
        </div>
      <div className="overlay"></div>
    </section>

    <Footer />
  </Layout>
);

export default IndexPage;
